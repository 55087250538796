<template>
  <article class="shipping-information">
    <header class="header">
      <h3 class="title text-md-uppercase">SHIPPING INFORMATION</h3>
    </header>
    <div class="content shopify-richtext">
      <p class="text text-md">
        We currently ship within Switzerland only, using Swiss Post services:
      </p>
      <ul class="list text-md">
        <li class="li">
          Post Economy (9.50 CHF): Delivered within 2 business days (Monday to Friday).
        </li>
        <li class="li">
          Post Priority (10.50 CHF): Next business day delivery, with Saturday delivery available.
        </li>
      </ul>
      <p class="text text-md">Enjoy free shipping on orders over 100 CHF</p>
    </div>
  </article>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.shipping-information {
  width: var(--inner-width);
  margin: 0 auto;
  padding: 1rem 0;

  > .header {
    padding: 1rem 0;
    border-bottom: 1px solid currentColor;
    text-align: center;
  }

  > .content {
    //text-align: center;

    > .list,
    > .text {
      padding: 1rem 0;
    }
  }
}
</style>
