<template>
  <div class="shopify-richtext" v-html="text"></div>
</template>

<script setup lang="ts">
import { jsonTextToHtml } from '~/shopify/jsonTextToHtml';

const props = defineProps<{
  schema: object | string;
  options?: { newLineToBreak?: boolean; scoped?: boolean };
}>();

const text = jsonTextToHtml(props.schema, props.options ?? { scoped: false });
</script>

<style lang="scss">
.shopify-richtext {
  ul {
    margin-left: 1rem;
  }
}
</style>
