export function formatPrice(price: { amount: number | string; currencyCode: string }) {
  let num: string | number = Number(price.amount);

  if (num === 0) {
    return `${num} ${price.currencyCode}`;
  }

  if (num % 1 === 0) {
    num = Math.trunc(num);
  } else {
    num = num.toFixed(2);
  }

  return `${num} ${price.currencyCode}`;
}
