<template>
  <div class="cart-container">
    <button
      :class="{ '-invert': !scrolled }"
      type="button"
      class="toggle"
      @click="$CartState.toggleCart(true)"
    >
      <span class="text _visually-hidden">Toggle cart</span>
      <img class="icon" src="/lock.svg" alt="Lock" />
    </button>
    <dialog
      v-if="cart"
      ref="dialogElement"
      class="inner"
      :open="isOpen"
      :class="{ '-loading': isLoading }"
    >
      <header class="header">
        <h2 class="title text-md-uppercase">Shopping Cart</h2>
        <button class="closebutton button-icon" type="button" @click="$CartState.toggleCart(false)">
          <span class="text _visually-hidden">Close Cart Dialog</span>
          <span class="icon"></span>
        </button>
      </header>
      <div v-if="cart.lines.edges.length" class="products">
        <CartProductVariant
          v-for="p in cart.lines.edges"
          :key="`card-product-variant-${p.node.id}`"
          :data="p.node"
        />
      </div>
      <p v-else class="textempty text-md">Cart is now empty</p>
      <footer v-if="$CartState.hasItemsInCart.value" class="footer">
        <div class="line">
          <p class="text text-md-uppercase">Total</p>
          <p class="value text-md-uppercase">
            {{ formatPrice(cart.cost.totalAmount) }}
          </p>
        </div>
        <div class="line">
          <p class="text text-md-uppercase">Shipping</p>
          <p class="value text-md">Calculated at checkout</p>
        </div>
        <div class="actions">
          <a
            :href="$CartState.hasItemsInCart.value ? cart.checkoutUrl : '#'"
            class="button button-primary -checkout -accent"
            >Go to checkout</a
          >
        </div>
      </footer>
    </dialog>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import CartProductVariant from '~/components/CartProductVariant.vue';
import { formatPrice } from '~/shopify/formatPrice';

const { $CartState } = useNuxtApp();

const cart = computed(function () {
  if ($CartState.cart.value) {
    return $CartState.cart.value;
  }

  return null;
});

const dialogElement = ref<HTMLDialogElement | null>(null);

onClickOutside(dialogElement, function () {
  if (isOpen.value) {
    $CartState.toggleCart(false);
  }
});

const { y: windowY } = useWindowScroll();

const scrolled = computed(function () {
  if (windowY.value) {
    return windowY.value > window.innerHeight * (0.5 - 0.15);
  }
});

const isOpen = computed(function () {
  return $CartState.isCartOpen.value;
});

const isLoading = computed(function () {
  return $CartState.isLoading.value;
});
</script>

<style scoped lang="scss">
.cart-container {
  width: fit-content;
  background-color: transparent;

  > .toggle {
    background: none;
    outline: none;
    border: none;
    width: 3.125rem;
    inset: var(--base-padding-x) var(--base-padding-x) auto auto;
    padding: 0;
    cursor: pointer;
    position: absolute;
    mix-blend-mode: difference;

    @include sm {
      width: 2.5rem;
    }

    @include md-lg {
      width: 3.125rem;
    }

    > .icon {
      width: 100%;
      height: fit-content;
    }

    &.-invert {
      filter: brightness(10) invert(1);
    }
  }

  > .inner {
    position: absolute;
    inset: 0 0 0 auto;
    transform: translateX(100%);
    background-color: var(--color-brown);
    color: var(--color-black);
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    height: 100vh;
    margin: 0;
    padding: 0;
    transition: transform 200ms;
    pointer-events: none;
    display: grid;
    grid-template-rows: minmax(0, auto) minmax(0, auto) 1fr;

    @include sm {
      width: 100%;
      border: none;
    }

    @include md-lg {
      width: 25rem;
      border-left: 1px solid var(--color-black);
      border-right: 1px solid var(--color-black);
    }

    &[open] {
      transform: translateX(0%);
      pointer-events: unset;
    }

    &.-loading {
      cursor: wait;
      pointer-events: none;

      * {
        pointer-events: none;
        opacity: 0.9;
      }
    }

    > .header {
      position: relative;
      padding: 1rem 0;
      display: flex;
      border-bottom: 1px solid var(--color-black);

      > .title {
        width: 100%;
        text-align: center;
      }

      > .closebutton {
        --size: 1.25rem;
        --background: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.56637 6.35558L7.56637 0.0655782L9.41637 0.0655781L9.41637 6.35558L16.0764 6.35558L16.0764 8.27958L9.41637 8.27958L9.41637 14.5696L7.56637 14.5696L7.56637 8.27958L0.906372 8.27958L0.906372 6.35558L7.56637 6.35558Z' fill='black'/%3E%3C/svg%3E%0A");

        position: absolute;
        inset: auto 1rem auto auto;
        transform: rotate(45deg);
      }
    }

    > .textempty {
      text-align: center;
      padding: 1rem;
    }

    > .footer {
      align-self: flex-end;

      > .line {
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      }

      > .actions {
        position: relative;
        padding: 2rem 1rem 1rem 1rem;

        > .button {
          width: 100%;
        }
      }
    }
  }
}
</style>
