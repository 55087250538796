<template>
  <header class="shop-site-header">
    <!--    <img class="backgroundgradient" src="/gradient_brown.png" alt="" role="presentation" />-->
    <CartContainer />
  </header>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.shop-site-header {
  width: 100%;
  position: fixed;
  z-index: 10;

  // with image:
  // > .backgroundgradient {
  //  position: absolute;
  //  inset: 0 0 auto 0;
  //  width: 100%;
  //  height: fit-content;
  //  z-index: -1;
  //  pointer-events: none;
  //}
}
</style>
