<template>
  <div class="cart-product-variant">
    <span class="title text-md-uppercase">{{ data.merchandise.subtitle.value }}</span>
    <div class="actions">
      <button
        type="button"
        class="button button-icon -minus"
        @click="updateQuantity(data.id, data.quantity - 1)"
      >
        <span class="text _visually-hidden">Remove one item</span>
        <span class="icon"></span>
      </button>
      <span class="quantity text-md-uppercase">{{ data.quantity }}</span>
      <button
        type="button"
        class="button button-icon -plus"
        @click="updateQuantity(data.id, data.quantity + 1)"
      >
        <span class="text _visually-hidden">Add one item</span>
        <span class="icon"></span>
      </button>
    </div>
    <button
      type="button"
      class="buttonremove button-secondary -delete"
      @click="$CartState.removeLineFromCart(data.id)"
    >
      Remove
    </button>
    <p class="price text-md-uppercase">
      <span class="value">{{ formatPrice(data.merchandise.price) }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import type { BaseCartLine } from '~/types/storefront.types';
import { formatPrice } from '~/shopify/formatPrice';

defineProps<{
  data: BaseCartLine;
}>();

const { $CartState } = useNuxtApp();

function updateQuantity(lineId: string, quantity: number) {
  return $CartState.updateCartLine({
    id: lineId,
    quantity,
  });
}
</script>

<style scoped lang="scss">
.cart-product-variant {
  padding: 1.5rem 1rem;
  display: grid;
  grid-template-columns: 1fr minmax(0, auto);
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'title actions' 'remove price';
  grid-gap: 0.5rem 0;

  > .title {
    grid-area: title;
  }

  > .actions {
    grid-area: actions;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    > .button {
      &.-minus {
        --background: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.5394 9.86646V11.6425H2.29742V9.86646H19.5394Z' fill='black'/%3E%3C/svg%3E%0A");
      }

      &.-plus {
        --background: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8804 9.53256H18.1704V11.3826H11.8804V18.0426H9.95638V11.3826H3.66638V9.53256H9.95638V2.87256H11.8804V9.53256Z' fill='black'/%3E%3C/svg%3E%0A");
      }
    }
  }

  > .buttonremove {
    grid-area: remove;
  }

  > .price {
    grid-area: price;
    align-self: flex-end;
    justify-self: flex-end;
  }
}
</style>
