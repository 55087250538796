<template>
  <div class="product-variant-card">
    <article class="inner">
      <div class="header">
        <figure v-if="data.image" class="figure">
          <img
            class="image"
            :src="data.image.url"
            :alt="data.image.altText ?? ''"
            :width="data.image.width ?? 100"
            :height="data.image.height ?? 100"
          />
        </figure>
        <h3 class="title text-md-uppercase">ROOTS PROTEIN</h3>
        <p class="subtitle text-md">
          <span class="text-md-uppercase">{{ data.title }}</span> — {{ weightInGrams }}g
        </p>
      </div>
      <div class="main">
        <p v-if="data.description" class="description text-md">{{ data.description.value }}</p>
      </div>
      <!--      <ShopifyRichtext-->
      <!--        v-if="data.descriptionRichtext?.value"-->
      <!--        class="text-md"-->
      <!--        :schema="data.descriptionRichtext.value"-->
      <!--      />-->
      <button type="button" class="button buttonbuy button-primary -dark" @click="addToCart()">
        <span class="label">Buy</span>
        <span class="price">{{ price }}</span>
      </button>
      <div class="accordion">
        <AccordionItem class="item" title="Benefits">
          <ShopifyRichtext
            v-if="data.benefits?.value"
            class="text-md"
            :schema="data.benefits.value"
          />
        </AccordionItem>
        <AccordionItem class="item" title="How to use">
          <ShopifyRichtext
            v-if="data.howToUse?.value"
            class="text-md"
            :schema="data.howToUse.value"
          />
        </AccordionItem>
        <AccordionItem class="item" title="Ingredients">
          <ShopifyRichtext
            v-if="data.ingredients?.value"
            class="text-md"
            :schema="data.ingredients.value"
          />
        </AccordionItem>
        <AccordionItem class="item" title="Nutrients">
          <NutrientsTable :serving-size="String(data.servingSize?.value)" :data="nutrients" />
        </AccordionItem>
      </div>
    </article>
    <!--    <RecipeCard v-if="data.recipe && data.recipe.value" :recipe-id="data.recipe.value" />-->
    <AboutSection />
    <ShippingInformation />
  </div>
</template>

<script setup lang="ts">
import type { ProductVariant } from '~/types/storefront.types';
import ShopifyRichtext from '~/components/ShopifyRichtext.vue';
import NutrientsTable from '~/components/NutrientsTable.vue';
import { formatPrice } from '~/shopify/formatPrice';

const { $CartState } = useNuxtApp();

const props = defineProps<{
  data: ProductVariant & {
    description?: {
      value: string;
    };
    descriptionRichtext?: {
      value: string;
    };
    recipe?: {
      value: string;
    };
    benefits?: {
      value: string;
    };
    howToUse?: {
      value: string;
    };
    netWeight?: {
      value: string;
    };
    ingredients?: {
      value: string;
    };
    servingSize?: {
      value: string;
    };
    nutrientsPer100g?: {
      value: string;
    };
    nutrientsPerServing?: {
      value: string;
    };
  };
}>();

const price = computed(function () {
  return formatPrice(props.data.price);
});

const weightInGrams = computed(function () {
  return Number(props.data.netWeight?.value ?? '0') * 1000;
});

function addToCart() {
  $CartState.addItemToCart(
    {
      quantity: 1,
      merchandiseId: props.data.id,
    },
    true,
  );
}

const nutrients = computed(function () {
  const nutrientsData = {
    per100g: null,
    perServing: null,
  };

  if (props.data.nutrientsPer100g) {
    nutrientsData.per100g = JSON.parse(props.data.nutrientsPer100g.value ?? {});
  }
  if (props.data.nutrientsPerServing) {
    nutrientsData.perServing = JSON.parse(props.data.nutrientsPerServing.value ?? {});
  }

  return nutrientsData;
});
</script>

<style scoped lang="scss">
.product-variant-card {
  --width: 100%;

  color: var(--color-brown);
  width: var(--width);
  flex-shrink: 0;
  position: relative;

  @include sm {
    padding: var(--base-padding-y) var(--base-padding-x);
  }

  @include md-lg {
    padding: 15vh 0 5vh 0;
  }

  @include sm {
    padding-top: 4rem;
  }

  > .inner {
    width: var(--inner-width);
    margin: 0 auto;

    > .header {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid currentColor;

      @include sm {
        margin-top: 5lvh;
      }

      > .figure {
        @include sm {
          height: 40lvh;
          padding-bottom: 2rem;
        }

        @include md {
          height: 50lvh;
          padding-bottom: 2rem;
        }

        @include lg {
          height: 65lvh;
          padding-bottom: 2rem;
        }

        > .image {
          height: 100%;
          width: auto;
        }
      }

      > .title {
        padding: 1rem 0 0rem 0;
      }

      > .subtitle {
        padding: 0.5rem 0 1rem 0;
      }
    }

    > .main {
      padding: 1rem 0 2rem 0;

      > .title {
        padding-bottom: 1rem;
        text-align: center;
      }
    }

    > .buttonbuy {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    > .accordion {
      padding: 3rem 0 2rem 0;

      > .item {
        transition: opacity 150ms;
      }
    }

    > .accordion:has(div.-open) {
      > .item:not(.-open) {
        opacity: 0.6;
      }
    }
  }
}
</style>
