<template>
  <div class="nutrients-table">
    <table class="table">
      <colgroup>
        <col span="1" />
        <col span="1" style="width: 9.5rem" />
        <col span="1" style="width: 9.5rem" />
      </colgroup>

      <thead class="thead">
        <tr class="tableheaderrow text-sm-uppercase">
          <th class="headercell">NUTRITIONAL VALUES</th>
          <th class="headercell">100 g</th>
          <th class="headercell">PORTION {{ servingSize }}</th>
        </tr>
      </thead>
      <tbody class="tbody">
        <tr v-for="it in nutrientsJsonSchemaKeys" :key="it" class="tablerow">
          <th class="tablecell -first text-sm-uppercase">{{ it }}</th>
          <td class="tablecell text-sm">{{ data.per100g?.[it] }}</td>
          <td class="tablecell text-sm">{{ data.perServing?.[it] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  servingSize: string;
  data: {
    per100g: null | Record<string, string>;
    perServing: null | Record<string, string>;
  };
}>();

const nutrientsJsonSchemaKeys = [
  'Energy',
  'Fat',
  'of which saturated fatty acids',
  'Carbohydrates',
  'of which sugar',
  'Dietary Fiber',
  'Protein',
  'Salt',
];
</script>

<style scoped lang="scss">
.nutrients-table {
  max-width: 100vw;
  overflow-x: auto;

  > .table {
    padding: 0.5rem 1rem 0.5rem 0;
    min-width: 38rem;
    border: none;
    outline: none;
    border-spacing: 0;

    > .thead,
    > .tbody {
      text-align: left;
    }

    th,
    td {
      padding: 0.1rem 0.5rem 0.1rem 0;
    }

    > .thead {
      > .tableheaderrow {
        > .headercell {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
