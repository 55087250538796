import type { StorefrontApiClient } from '@shopify/storefront-api-client';
import type { Product } from '~/types/storefront.types';

export function getProductByHandle(handle: string) {
  const nuxtApp = useNuxtApp();

  return (nuxtApp.$ShopifyClient as StorefrontApiClient).request<{ product: Product }>(
    `#graphql
      query getProductByHandle {
          product(handle: "${handle}") {
              id
              title
              description
              seo {
                  title
                  description
              }
              variants(first: 1) {
                  edges {
                      node {
                          id
                          title
                          image {
                              altText
                              url
                              width
                              height
                          }
                          quantityAvailable
                          price {
                              amount
                              currencyCode
                          }
                          description: metafield(namespace: "custom", key: "description") {
                              value
                          }
                          descriptionRichtext: metafield(namespace: "custom", key: "description_richtext") {
                              value
                          }
                          subtitle: metafield(namespace: "descriptors", key: "subtitle") {
                              value
                          }
                          netWeight: metafield(namespace: "custom", key: "weight") {
                              value
                          }
                          recipe: metafield(namespace: "custom", key: "recipe") {
                              value
                          }
                          benefits: metafield(namespace: "custom", key: "benefits") {
                              value
                          }
                          howToUse: metafield(namespace: "custom", key: "how_to_use") {
                              value
                          }
                          ingredients: metafield(namespace: "custom", key: "ingredients") {
                              value
                          }
                          servingSize: metafield(namespace: "custom", key: "serving_size") {
                              value
                          }
                          nutrientsPer100g: metafield(namespace: "custom", key: "nutrients_100g") {
                              value
                          }
                          nutrientsPerServing: metafield(namespace: "custom", key: "nutrients_per_serving") {
                              value
                          }
                      }
                  }
              }
          }
      }`,
  );
}
