<template>
  <div ref="accordionItemElement" class="accordion-item" :class="{ '-open': isOpen }">
    <h4 class="header">
      <button
        :id="`accordion-${id}`"
        type="button"
        :aria-expanded="isOpen ? 'true' : 'false'"
        class="trigger"
        :aria-controls="id"
        @click="toggleOpen()"
      >
        <span class="title text-md-uppercase">
          {{ title }}
          <!--          <span class="icon"></span>-->
        </span>
      </button>
    </h4>
    <div
      :id="id"
      role="region"
      :hidden="!isOpen"
      :aria-labelledby="`accordion-${id}`"
      class="panel"
    >
      <div class="inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';

const props = defineProps<{
  title: string;
}>();

const isOpen = ref<boolean>(false);

const toggleOpen = useToggle(isOpen);

const id = computed(function () {
  return `id-${props.title.replace(/\W/g, '_')}`;
});

const accordionItemElement = ref<HTMLElement | null>(null);

onClickOutside(accordionItemElement, function () {
  if (isOpen.value) {
    toggleOpen(false);
  }
});
</script>

<style scoped lang="scss">
.accordion-item {
  padding: 0;
  margin-bottom: 0.25rem;

  &:focus-within {
    //border-color: hsl(216deg 94% 43%);

    > .header {
      //background-color: hsl(0deg 0% 97%);
    }
  }

  > .header {
    margin-bottom: 0.25rem;

    > .trigger {
      color: currentColor;
      display: block;
      font-size: 1rem;
      font-weight: normal;
      margin: 0;
      position: relative;
      text-align: left;
      width: 100%;
      outline: none;
      border: none;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 100ms;

      &:focus,
      &:hover {
        //background-color: color-mix(in srgb, #000 85%, #ab8162);

        > .title {
          > .icon {
            //border-color: hsl(216deg 94% 43%);
          }
        }
      }

      &:focus {
        outline: 4px solid transparent;
      }

      &[aria-expanded='true'] {
        > .title {
          > .icon {
            transform: translateY(-50%) rotate(-135deg);
          }
        }
      }

      > .title {
        pointer-events: none;

        > .icon {
          border: solid currentcolor;
          border-width: 0 2px 2px 0;
          height: 0.5rem;
          pointer-events: none;
          position: absolute;
          right: 2em;
          top: 50%;
          transform: translateY(-60%) rotate(45deg);
          width: 0.5rem;
        }
      }
    }
  }

  > .panel {
    --transitionLength: 0.4s;
    --transitionTiming: ease;

    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition:
      grid-template-rows 300ms ease-in-out,
      margin-bottom 300ms;
    padding: 0 0 0.1rem 0;

    &[hidden] {
      //display: none;
    }

    &:not([hidden]) {
      grid-template-rows: 1fr;
      margin-bottom: 0.5rem;

      > .inner {
      }
    }

    > .inner {
      min-height: 0;
    }
  }
}
</style>
