<template>
  <div class="index-page">
    <div class="backgroundgradient">
      <div class="inner"></div>
    </div>
    <ShopSiteHeader class="header" />
    <main class="main">
      <ProductContainer v-if="product.data?.product" :product="product.data.product" />
    </main>
  </div>
</template>

<script setup lang="ts">
import { getProductByHandle } from '~/shopify/getProductByHandle';
import ShopSiteHeader from '~/components/ShopSiteHeader.vue';

const product = await getProductByHandle('protein-powder');
</script>

<style lang="scss">
.index-page > .main,
.index-page > .header {
  visibility: hidden;
  opacity: 0;
}

.index-page > .backgroundgradient {
  transform: translateY(-100lvh);
  opacity: 0;
}

html[data-loading-state='text'],
html[data-loading-state='logo'] {
  .index-page > .main,
  .index-page > .header {
    visibility: hidden;
    opacity: 0;
  }
}

html[data-loading-state='logo'],
html[data-loading-state='finished'] {
  .index-page > .backgroundgradient {
    transform: translateY(0);
    opacity: 1;
  }
}

html[data-loading-state='text'] {
  .index-page > .backgroundgradient {
    transform: translateY(-100lvh);
    opacity: 0;
  }
}

html[data-loading-state='finished'] {
  .index-page > .main,
  .index-page > .header {
    opacity: 1;
    transition: opacity 500ms linear 500ms;
    visibility: visible;
  }
}

.index-page {
  > .backgroundgradient {
    position: absolute;
    inset: 0 0 auto 0;
    width: 100%;
    height: 58lvh;
    pointer-events: none;
    transition:
      transform 1000ms var(--ease-out-quint),
      opacity 1000ms var(--ease-out-quint);
    overflow: clip;

    > .inner {
      width: auto;
      height: 100%;
      background-image: url('/gradient_brown.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom center;
      scale: 1.01;
    }
  }

  > .main {
    position: relative;
  }
}
</style>
