<template>
  <article class="about-section">
    <header class="header">
      <h3 class="title text-md-uppercase">ABOUT ROOTS PROTEIN & PROTOCOL INTEGRATION</h3>
    </header>
    <div class="content">
      <!--      <h3 class="title text-md-uppercase">ABOUT ROOTS PROTEIN & PROTOCOL INTEGRATION</h3>-->
      <p class="text text-md">
        Since 2015, Roots has been on a mission to inspire plant-powered living. We've developed
        Roots Protein, a clean, natural, plant-based protein designed for everyone—whether you’re an
        athlete, a busy professional, or simply aiming for better health. With minimal ingredients
        and great taste, it seamlessly fits into your daily routine.
      </p>
      <p class="text text-md">
        Research shows that high-quality protein supports muscle recovery and overall health. No
        matter your age or lifestyle, Roots Protein is here to meet your nutritional needs. The
        recommended daily intake for adults is 0.8g of protein per kg of body weight. Roots Protein
        helps you get there with ease.
      </p>
    </div>
  </article>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.about-section {
  width: var(--inner-width);
  margin: 0 auto;
  //border-top: 1px solid currentColor;
  padding: 1rem 0;

  > .header {
    padding: 1rem 0;
    border-bottom: 1px solid currentColor;
    text-align: center;
  }

  > .content {
    //text-align: center;

    > .text {
      padding: 1rem 0;
    }
  }
}
</style>
